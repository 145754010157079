<template>
  <main class="login">
      <div class="container-fluid" style="margin:0; padding:0">
          <div class="col-6 opaque p-5 text-center" style="height:100vh; background:rgba(0,0,0,0.7)">
              <h1>Bienvenido</h1>
              <p>Bomberos Municipales Departamentales</p>
              <img src="@/assets/logo-xl.png" alt="ASOMBOMB" style="height:10rem">

                <div class="row">
                    <div class="col-6 offset-3 text-start">
                        <form class="mt-5" style="width:100%" @submit.prevent="login()">
                            <label >Usuario:</label>
                            <input v-model="user.username" type="text" class="form-control text-white mb-2">
                            <label >Password:</label>
                            <input v-model="user.password" type="password" class="form-control text-white mb-3">

                            <button class="btn-1 float-end" type="submit">Ingresar</button>
                        </form>
                    </div>
                </div>
          </div>
      </div>
  </main>
</template>

<script>
export default {
    
    data(){
        return{
            user: {
                username:'',
                password: ''
            }
        }
    },
    mounted(){

    },
    methods:{
        async login(){
            try {
                let res = await this.$store.dispatch('login', {data: this.user});
                if (res.data ){
                    this.$store.commit('setToken', res.token)
                    this.$store.commit('setUser', res.data)
                    this.$router.push('/home')
                }
            } catch (error) {
                console.error('error', error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.login{
    background: url(~@/assets/bg.jpg) no-repeat;    
    background-size: cover;
    z-index: 9999;
    position: fixed;
    background-position: center;
    top:0; left: 0; bottom: 0; right: 0;
}
</style>